'use client';

import './styles.scss';

import { TextAreaField } from '@/components/Forms/Fields/TextAreaField';
import { DarkPhoneField } from '@/components/Forms/Fields/DarkPhoneField';
import { TextField } from '@/components/Forms/Fields/TextField';

import { ArrowButton } from '@/components/Links/ArrowButton';
import { PurpleSpinner } from '@/components/Loadings';

import { handleResponse } from '@/services/responseHandler.service';

import { getCookies } from 'cookies-next';
import parse from 'html-react-parser';

import { useForm } from 'react-hook-form';
import { useReCaptcha } from 'next-recaptcha-v3';
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';

import { IBaseResponse, leadsService } from '@/services/form.service';

import { FORMS, FormTypes, POTENTIAL_CLIENT_OPTION } from '@/constants/forms';

import { zodResolver } from '@hookform/resolvers/zod';
import { validationSchema } from './validationSchema';

import { DarkSelectField } from '@/components/Forms/Fields/DarkSelectField';

import { getGclid, setGclid } from '@/helpers/leadSource';
import { formatPhoneNumber } from '@/helpers/phoneNumber';
import { HISTORY_SOURCE } from '@/constants/localStorage';
import clsx from 'clsx';
import { CheckboxField } from '@/components/Forms/Fields/CheckboxField';
import { SlideBackground } from '@/components/Backgrounds/Slide';

interface IProps {
  setFormSubmissionStatus?: Dispatch<SetStateAction<boolean>>;
  showPotentialRole?: boolean;
  submitButtonClassName?: string;
  popupForm?: boolean;
  testId?: string;
  caseStudyForm?: boolean;
  className?: string;
  isDark?: boolean;
  isOutlineThin?: boolean;
  successMessageClassName?: string;
  captchaClassName?: string;
}

export const ContactFrom = ({
  setFormSubmissionStatus,
  showPotentialRole = true,
  submitButtonClassName,
  popupForm = false,
  testId,
  caseStudyForm = false,
  className,
  isDark = false,
  isOutlineThin = false,
  successMessageClassName,
  captchaClassName,
}: IProps) => {
  const { executeRecaptcha } = useReCaptcha();

  const [responseStatus, setResponseStatus] = useState<boolean | null>(null);
  const [responseMessage, setResponseMessage] = useState<string>('');
  const successMessageElement = useRef(null);

  useEffect(() => {
    setGclid();
  }, []);

  useEffect(() => {
    if (responseStatus && successMessageElement.current) {
      if (setFormSubmissionStatus) {
        setFormSubmissionStatus(true);
      }

      const element = successMessageElement.current as HTMLElement;
      element.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [responseStatus, setResponseStatus, setFormSubmissionStatus]);

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
    trigger,
    reset,
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues: {
      firstName: '',
      lastName: '',
      phone: '',
      email: '',
      companyName: '',
      additionalInfo: '',
      potentialRole: '',
      consentPersonalData: false,
    },
    resolver: zodResolver(validationSchema(showPotentialRole)),
  });

  const onSubmit = async (data: any) => {
    const historySource = localStorage?.getItem(HISTORY_SOURCE);
    const token = await executeRecaptcha('form_submit');
    const { _ga, gclid, user_country } = getCookies();
    const leadSource = getGclid(gclid, historySource);

    const potentialRoleValue = showPotentialRole ? data.potentialRole.value : POTENTIAL_CLIENT_OPTION;

    const newData = {
      leadCampaign: 'Form: syberry.com',
      leadSource: leadSource,
      analytics_client_id: _ga || '',
      cookies: {
        _ga,
        form_data: new URLSearchParams({
          leadSource: leadSource,
          leadCampaign: 'Form: syberry.com',
          analytics_client_id: _ga || '',
          ...data,
          potentialRole: potentialRoleValue,
        }).toString(),
        http_referer: historySource || '',
        user_country: user_country || '',
        gclid: gclid || '',
      },
      'g-recaptcha-response': token,
      attachmentsIds: [],
      valid: 1,
      ...data,
      potentialRole: potentialRoleValue,
      isClient: potentialRoleValue === POTENTIAL_CLIENT_OPTION,
      phone: formatPhoneNumber(data.phone),
    };

    try {
      const response: IBaseResponse = await leadsService.contactForm(newData);

      const processedResponse = handleResponse(response, FormTypes.CONTACT_US);

      setResponseStatus(processedResponse.isSuccess);
      setResponseMessage(processedResponse.message);
    } catch (e) {
      setResponseStatus(false);
      setResponseMessage(
        `Something went wrong. Please reload the page or send your request to 
        <a href="mailto:contact@syberry.com">contact@syberry.com</a>.`,
      );
    }

    reset();
  };

  return (
    <>
      {responseStatus ? (
        <p
          ref={successMessageElement}
          data-testid={`${testId || FORMS.contactUs.id}-success-response-message`}
          className={clsx('w-full text-black-5 font-light text-[28px] leading-[33.5px] text-center font-monoItalic', {
            [`${successMessageClassName}`]: !!successMessageClassName,
          })}
        >
          {parse(responseMessage)}
        </p>
      ) : (
        <form
          id={FORMS.contactUs.id}
          data-testid={testId || FORMS.contactUs.id}
          onSubmit={handleSubmit(onSubmit)}
          className={clsx('contact-us-form w-full flex flex-col box-border', {
            [`${className}`]: className,
          })}
        >
          <div className={caseStudyForm ? 'xl-1:flex xl-1:justify-between xl-1:md:gap-[20px]' : ''}>
            <div className={`flex flex-col md:flex-row ${caseStudyForm ? 'md:gap-[20px] w-full' : 'md:gap-[32px]'}`}>
              <TextField
                testId={`${testId || FORMS.contactUs.id}-name`}
                name="firstName"
                label="First name"
                control={control}
                disabled={isSubmitting}
                placeholder="First name"
                labelClassname={clsx('text-white font-sans normal-case font-semibold tracking-normal', {
                  ['text-[12px] sm:text-base']: popupForm,
                })}
                inputClassname={`bg-white10 placeholder:text-white50 text-white ${
                  isOutlineThin ? 'focus:!ring-1' : ''
                }`}
                dark
              />

              <TextField
                testId={`${testId || FORMS.contactUs.id}-last-name`}
                name="lastName"
                label="Last name"
                control={control}
                disabled={isSubmitting}
                placeholder="Last name"
                labelClassname={clsx(' text-white font-sans normal-case font-semibold tracking-normal', {
                  ['text-[12px] sm:text-base']: popupForm,
                })}
                inputClassname={`bg-white10 placeholder:text-white50 text-white ${
                  isOutlineThin ? 'focus:!ring-1' : ''
                }`}
                dark
              />
            </div>

            <div
              className={`flex flex-col md:flex-row ${
                caseStudyForm ? 'md:flex-row-reverse md:gap-[20px] w-full' : 'md:gap-[32px]'
              }`}
            >
              <TextField
                testId={`${testId || FORMS.contactUs.id}-email`}
                name="email"
                label="Email"
                control={control}
                disabled={isSubmitting}
                placeholder="you@company.com"
                labelClassname={clsx(' text-white font-sans normal-case font-semibold tracking-normal', {
                  ['text-[12px] sm:text-base']: popupForm,
                })}
                inputClassname={`bg-white10 placeholder:text-white50 text-white ${
                  isOutlineThin ? 'focus:!ring-1' : ''
                }`}
                dark
              />

              <DarkPhoneField
                testId={`${testId || FORMS.contactUs.id}-phone`}
                name="phone"
                label="Phone number"
                placeholder="(201) 555 0123"
                control={control}
                disabled={isSubmitting}
                labelClassname={clsx('', {
                  ['text-[12px] sm:text-base']: popupForm,
                })}
                inputClassname={`${isOutlineThin ? '[&.PhoneInput--focus]:!ring-1' : ''}`}
              />
            </div>

            <div
              className={`flex flex-col md:flex-row ${caseStudyForm ? 'md:gap-[20px] xl-1:w-[50%]' : 'md:gap-[32px]'}`}
            >
              {showPotentialRole && (
                <DarkSelectField
                  isThinOutline={isOutlineThin}
                  testId={`${testId || FORMS.contactUs.id}-potential-role`}
                  name="potentialRole"
                  control={control}
                  label="I am a..."
                  placeholder="I am a..."
                  disabled={isSubmitting}
                  labelClassname={clsx(' text-white font-sans normal-case font-semibold tracking-normal', {
                    ['text-[12px] sm:text-base']: popupForm,
                  })}
                  options={FORMS.contactUs.potentialRoleOptions}
                />
              )}

              <TextField
                testId={`${testId || FORMS.contactUs.id}-company`}
                name="companyName"
                label="Company"
                control={control}
                disabled={isSubmitting}
                placeholder="Company"
                labelClassname={clsx(' text-white font-sans normal-case font-semibold tracking-normal', {
                  ['text-[12px] sm:text-base']: popupForm,
                })}
                inputClassname={`bg-white10 placeholder:text-white50 text-white ${
                  isOutlineThin ? 'focus:!ring-1' : ''
                }`}
                dark
              />
            </div>
          </div>

          <TextAreaField
            testId={`${testId || FORMS.contactUs.id}-additional-info`}
            name="additionalInfo"
            label="Message"
            control={control}
            disabled={isSubmitting}
            placeholder="Type your message..."
            labelClassname={clsx(' text-white font-sans normal-case font-semibold tracking-normal', {
              ['text-[12px] sm:text-base']: popupForm,
            })}
            inputClassname={`bg-white10 placeholder:text-white50 text-white ${
              caseStudyForm ? 'h-[120px]' : 'h-[138px]'
            } ${isOutlineThin ? 'focus:!ring-1' : ''}`}
            dark
          />

          <CheckboxField
            darkMode
            testId={`${testId || FORMS.contactUs.id}-consent-personal-data`}
            name="consentPersonalData"
            control={control}
            triggerRevalidation={() => trigger('consentPersonalData')}
            inputClassName="border-white bg-transparent rounded-[6px] w-[20px] h-[20px]"
            label={
              <>
                I consent to Syberry Corporation (“Syberry”) processing of my personal data as set out in the{' '}
                <a
                  className="underline cursor-pointer hover:text-purple-8"
                  href="/privacy-policy/"
                  target="_blank"
                >
                  Privacy Policy
                </a>{' '}
                and{' '}
                <a
                  className="underline cursor-pointer hover:text-purple-8"
                  href="/cookie-policy/"
                  target="_blank"
                >
                  Cookies Policy
                </a>{' '}
                and agree that due to the international presence of Syberry Corporation such processing may take place
                outside of my home jurisdiction.
              </>
            }
            disabled={false}
          />

          <span
            className={clsx('text-white mb-2xl', {
              ['text-[10px] sm:text-base']: popupForm,
              [`${captchaClassName}`]: !!captchaClassName,
            })}
          >
            This site is protected by reCAPTCHA and the Google{' '}
            <a
              className="underline font-mono hover:text-purple-8"
              href="https://policies.google.com/privacy"
              target="\_blank"
              rel="noreferrer noopener"
            >
              Privacy Policy
            </a>{' '}
            and{' '}
            <a
              className="underline font-mono hover:text-purple-8"
              href="https://policies.google.com/terms"
              target="\_blank"
              rel="noreferrer noopener"
            >
              Terms of Service
            </a>{' '}
            apply.
          </span>

          <div
            className={clsx('flex justify-between items-center gap-base relative z-10', {
              [`${submitButtonClassName}`]: submitButtonClassName,
            })}
          >
            <ArrowButton
              testId={`${testId || FORMS.contactUs.id}-submit-button`}
              buttonClassname="border-white/40 disabled:opacity-50 disabled:pointer-events-none text-white"
              buttonTextClassname="relative z-[1] font-semibold leading-base flex items-center gap-[4px] group"
              gradient={
                caseStudyForm
                  ? isDark
                    ? 'bg-conic-gradient-purple'
                    : 'bg-conic-gradient-white'
                  : 'bg-conic-gradient-purple'
              }
              arrowVariant="secondary"
              disabled={isSubmitting}
              gradientPosition="w-[310%] h-[310%]"
              backgroundSlot={<SlideBackground className="before:bg-linear-green-2 bg-linear-green-1" />}
            >
              Submit
            </ArrowButton>

            <div data-testid={`${testId || FORMS.contactUs.id}-response-message`}>
              {!responseStatus && (
                <p className="text-base text-white [&>a]:text-purple-6 [&>a:hover]:opacity-80">
                  {parse(responseMessage)}
                </p>
              )}
              {isSubmitting && <PurpleSpinner />}
            </div>
          </div>
        </form>
      )}
    </>
  );
};
